@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap'); /* Nunito Sans */

/* HOME PAGE BACKGROUND */

.home-container {
  height: 100%;
  width: 100%;
  z-index: 0;
  margin: 0;
  padding: 0;
  background-color: black;
  overflow-x: hidden !important;
}

/* HERO SECTION */

.hero-section {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-color: black;
}

/* HERO VIDEO

.hero-background {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.hero-video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: translateY(12%);
}

*/

.hero-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.motto {
  font-size: 4.0em;
  font-family: 'Bebas Neue', sans-serif;
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  margin-bottom: -50px;
}

.request-narcan {
  visibility: hidden;
  opacity: 0;
  animation: fadeInButton 2s forwards;
  animation-delay: 2.5s; /* Built-in delay to match motto typing effect */
}

.request-narcan img {
  height: auto;
  width: 200px;
  padding: 69px;
  vertical-align: middle;
  transition: filter 0.4s;
}

.request-narcan img:hover {
  filter: brightness(2);
}

.arrow {
  visibility: hidden;
  opacity: 0;
  position: relative;
  margin-top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 3em;
  transform: rotate(90deg);
  animation: fadeInArrow 2s forwards, bounce 0.75s infinite alternate;
  animation-delay: 2.5s; /* Built-in delay to display after motto typing effect */
}

@keyframes fadeInButton {
  from {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeInArrow {
  from {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: translateX(0%) translateY(0) rotate(90deg);
  }
  100% {
    transform: translateX(0%) translateY(10px) rotate(90deg);
  }
}

/* MISSION SECTION */

.mission-section {
  display: flex;
  background-color: #000;
  color: white;
  background-image: url('/public/overdose_hand_ribbon.png');
  background-size: cover;
  background-position: center;
  height: 85vh;
}

.mission-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mission-header {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 3em;
  margin-left: 20px;
}

.mission-highlight {
  background: linear-gradient(to right, #6a00f4, #9226f0, #ff5fa8);
  -webkit-background-clip: text;
  color: transparent;
}

.mission-right {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.mission-text {
  position: absolute;
  text-align: center;
  color: white;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1.5em;
  max-width: 80%;
}

/* INITIATIVES SECTION */

.initiatives-section {
  background-color: black;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.initiative:hover .icon-hover {
  opacity: 1;
}

.initiatives-header {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 3em;
  margin-top: 80px;
  margin-bottom: 20px;
}

.initiatives-highlight {
  background: linear-gradient(to right, #6a00f4, #9226f0, #ff5fa8);
  -webkit-background-clip: text;
  color: transparent;
}

.initiatives-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
}

.initiative {
  flex: 1;
  max-width: 20%;
  margin: 10px;
  position: relative;
  background-image: url('/public/city background.png');
  background-size: 400%;
  height: 300px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.5s ease;
   border-radius: 25px;
}

.initiative-1 {
  background-position: 0% 0%;
}

.initiative-2 {
  background-position: 25% 0%;
}

.initiative-3 {
  background-position: 50% 0%;
}

.initiative-4 {
  background-position: 75% 0%;
}

.initiative-5 {
  background-position: 100% 0%;
}

.initiative-title {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 1.5em;
  margin: 10px 0;
  transition: opacity 0.7s;
}

.initiative-description {
  width: 100%;
  height: 0%;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  transition: height 0.7s;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
}

.initiative-description h3 {
  margin-bottom: 0;
}

.initiative:hover .initiative-description {
  height: 100%;
}

.initiative:hover .initiative-title {
  opacity: 0;
}

.initiative:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* IMPACT SECTION */

.impact-section {
  background-color: black;
  color: white;
  padding: 40px 20px;
  padding-left: 100px;
  padding-right: 100px;
  text-align: center;
}

.impact-header {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 3em;
  margin-bottom: 40px;
}

.impact-highlight {
  background: linear-gradient(to right, #6a00f4, #9226f0, #ff5fa8);
  -webkit-background-clip: text;
  color: transparent;
}

.impact-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
}

.impact-item {
  flex: 1;
  max-width: 30%;
  margin: 10px;
  text-align: center;
  position: relative;
}

.impact-item::before {
  content: '';
  display: block;
  width: 50%;
  height: 2px;
  background-color: white;
  margin: 0 auto 10px auto;
}

.impact-number {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 4em;
  color: #ff23fd;
  font-style: italic;
  margin-top: 15px;
  margin-bottom: 15px;
}

.impact-text {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1.2em;
  margin-top: 10px;
}

/* CLOSING SECTION */

.closing-section {
  background-color: black;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.closing-text {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1.2em;
  max-width: 800px;
  margin: 0 auto 20px auto;
  margin-bottom: 40px;
}

.closing-buttons {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.closing-button {
  font-family: 'bebas neue', sans-serif;
  font-size: 1.3em;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 25px;
  border: 2px solid white;
  display: inline-block;
  width: 175px;
  text-align: center;
  transition: transform 0.3s;
}

.request-narcan-button {
  margin-top: 20px;
  background-color: white;
  color: black;
}

.report-overdose-button {
  background-color: black;
  color: white;
}

.closing-button:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* MEDIA QUERY FOR MOBILE DEVICES */

@media (max-width: 768px) {

  /* HERO SECTION */

  .hero-background {
    display: none;
  }
  
  .motto {
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 50px;
  }

  .hero-content {
    position: absolute;
    top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .request-narcan img {
    padding-top: 100px;
    width: 250px;
  }
  
  /* MISSION SECTION */

  .mission-section {
    flex-direction: column;
    height: auto;
    align-items: center;
    background-position: top;
    padding: 20px 0;
    background-image: none;
    margin-bottom: 20px;
  }

  .mission-left {
    flex: none;
    width: 100%;
    justify-content: center;
    padding: 10px 0;
    background-color: transparent;
  }

  .mission-header {
    font-size: 3em;
    text-align: center;
    padding: 10px 0;
    margin-left: 0;
    margin-top: 25px;
  }

  .mission-right {
    flex: none;
    width: 100%;
    justify-content: center;
    padding: 10px 0;
  }

  .mission-text {
    font-size: 1.2em;
    text-align: center;
    padding: 20px;
  }

  /* INITIATIVES SECTION */

  .initiatives-section {
    margin-bottom: 30px;
    position: relative; 
    z-index: 1; 
  }

  .initiatives-content {
    flex-direction: column;
    align-items: center;
    position: static;
  }

  .initiatives-header {
    margin-bottom: 30px;
  }

  .initiative {
    height: 100px; 
    min-height: 60px;
    max-width: 100%;
    width: 100%;
    margin: 10px 0;
    background-size: 100%;
    position: relative; 
    z-index: 2; 
  }
  
  .initiative-title {
    font-size: 1.2em;
  }

  .initiative-description {
    font-size: 1.3em;
    padding: 0 5px;
    display: none; 
    transition: all 0.3s ease;
    position: absolute; 
  }

  .initiative-1 { background-position: 0% 0%; }
  .initiative-2 { background-position: 0% 25%; }
  .initiative-3 { background-position: 0% 50%; }
  .initiative-4 { background-position: 0% 75%; }
  .initiative-5 { background-position: 0% 100%; }

  .initiative:hover {
    height: auto; 
    min-height: 250px; 
    position: relative; 
    width: 100%;
    z-index: 3; 
    background-size: cover;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .initiative:hover .initiative-description {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .initiative:hover .initiative-title {
    display: none; 
  }

  /* IMPACT SECTION */

  .impact-section {
    padding: 10px;
    margin-bottom: 25px;
  }

  .impact-content {
    margin-top: -40px;
    flex-direction: column;
  }

  .impact-item {
    max-width: 100%;
  }

  .impact-item::before {
    display: none;
  }



  /* CLOSING SECTION */

  .closing-section {
    margin-top: 0;
    padding-top: 0;
  }
  
  .closing-buttons {
    gap: 10px;
  }

  .closing-button {
    margin: 10px 0;
  }

  .closing-text::before {
    content: '';
    display: block;
    width: 25%;
    height: 1px;
    background-color: white;
    margin: 0 auto 75px auto;
  }

}