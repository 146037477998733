  /* src/index.css or src/App.css */

  /* For Webkit-based browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 16px; /* Increase the width of the scrollbar */
    height: 16px; /* Increase the height of the scrollbar */
    z-index: 1000;
  }

  ::-webkit-scrollbar-track {
    background: black; /* Background of the scrollbar track */
    border-radius: 10px; /* Rounded corners for the track */
  }

  ::-webkit-scrollbar-thumb {
    background: grey; /* Color of the scrollbar thumb */
    border-radius: 8px; /* Rounded corners for the thumb */
    border: 4px solid black; /* Border to create spacing around the thumb */
    transition: background 0.3s; /* Add a transition effect for hover */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: lightgrey; /* Change color on hover */
  }

  /* Optional: For Internet Explorer and Edge */
  body {
    -ms-overflow-style: -ms-autohiding-scrollbar; /* Auto-hiding scrollbar */
  }

  
  /* Additional styling for all scrollbars */
  body::-webkit-scrollbar {
    height: 16px;
    width: 16px;
    background-color: black;
    }
    
    
  body::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 6px;
    border: 4px solid black;
    transition: background 0.3s;
  }

  body::-webkit-scrollbar-thumb:hover {
    background-color: lightgrey;
  }

  