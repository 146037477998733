@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap'); /* BEBAS NEUE */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@700&display=swap'); /* NUNITO SANS CONDENSED */

.founders-page-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh; 
    font-family: 'Bebas Neue', sans-serif;
    overflow: hidden; /* Remove scroll feature */
    position: relative; /* Ensure absolute positioned children are contained */

}

html, body {
    overflow: hidden; /* Remove scroll bar */
    height: 100%;
}

.founders-page-container p {
    margin-left: 350px; 
    margin-right: 350px; 
    text-align: center;
    font-family: 'nunito sans', sans-serif;
    font-size: 17px;
}

.position-container {
    display: flex;
    align-items: center;
}


.box-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; 
}

.box-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.box {
    width: 200px;
    height: 200px;
    margin: 20px;
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.box:hover img {
    transform: scale(1.1);
}

.description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding: 10px; 
    box-sizing: border-box;
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
}

.box:hover .description {
    opacity: 1;
}

.additional-image-container {
    width: 200px; 
    margin: 0 10px; 
    margin-bottom: 10px; /* Add space between position title and LinkedIn icon */

}

.additional-image-container h2, .additional-image-container h3 {
    margin: 0;
}

.linkedin-logo {
    width: 15px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    
}


.position-container a {
    color: white; /* Set default color to white */
    margin: 0 6px; /* Adjust margin between icons */
    font-size: 18px; /* Adjust icon size */
    transition: color 0.3s; /* Add transition effect for color change */
    text-decoration: none;
  }
  
  .position-container a:hover {
    background: linear-gradient(to right, #6a00f4, #9226f0, #ff5fa8); /* Gradient colors */
    -webkit-background-clip: text;
    color: transparent;
  }


.additional-image {
    max-width: 100px; 
    height: auto;

}

.additional-image-container h3 {
    font-size: 15px;
    white-space: nowrap; 
    margin-bottom: 10px; /* Add space between position title and LinkedIn icon */
}

@media (max-width: 480px) {

    html, body {
        overflow: auto; /* Enable scrolling on mobile */
    }
    .founders-page-container{
        overflow-y: scroll; /* Enable vertical scrolling */
        -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
        font-family: 'Bebas Neue';
        background-color: black; /* Set background color to black */

    }
    .founders-page-container p {
        display: block;
        font-size: 11px;
        margin-left: 10px;
        margin-right: 10px;
      }

    .box-container {
        display: flex;
        justify-content: center;
        align-items: flex-start; 
        width: 80%;
        height: auto;
        margin: 20px 30px;
        flex-direction: column;
    }

    .box-wrapper {
        flex-direction:column;
        align-items: center;
    }

}

