@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap'); /* Nunito Sans */

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  font-family: 'Nunito Sans', sans-serif;
}

.sidenav.open {
  width: 400px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: white;
  display: block;
  white-space: nowrap;
  text-overflow: clip;

}

.sidenav .sidenav-elements a:hover {
 background: linear-gradient(to right, #6a00f4, #f09526, #ff5fa8); /* Gradient colors */
 -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.sidenav .sidenav-logo img {
    height: 50px;
    width: auto;
    transition: opacity 0.2s; /* Smooth fade effect on hover */
  }

.sidenav .sidenav-logo img:hover {
  opacity: 0.9;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 40px;
  color: white;
  background-color: #111;
  border: none;
  padding: 10px;
  padding-top: 20px;
  cursor: pointer;
  transition: color 0.5s ease;
}

.sidenav .closebtn:hover {
  color: #818181;
}

.dropdown-toggle { 
  background-color: transparent;
  border: none;
  color: #818181;
  font-size: 25px;
  cursor: pointer;
  padding: 8px 8px 8px 32px;
  text-align: left;
  width: 100%;
  display: block;
  white-space: nowrap;
  text-overflow: clip;
}

.dropdown-menu {
  padding-left: 20px;
}

.dropdown-menu a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  transition: 0.3s;
  white-space: nowrap;
  text-overflow: clip;
}

.dropdown-menu a:hover {
  color: #f1f1f1;
}

.sidenav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.sidenav-elements {
    padding-left: 30px;
    font-family: 'Nunito Sans', sans-serif;
  
}

.sidenav-about-children a {
    margin-left: 20px;
    font-size: 20px !important;
    font-family: 'Nunito Sans', sans-serif;
    color: lightgrey;
}

.navbar-request-narcan2 img {
    display: none; 
}

@media (max-width: 768px) {

  .dropdown-toggle { 
    padding: 0px 8px 8px 24px;
  }

  .navbar-request-narcan2 {
      display: flex;
      justify-content: flex-start; /* Align the button to the left */
      align-items: center;
      padding: 0;
      margin: 0;
      width: 100%;
      transition: none;
      position: absolute;
      left: 50px;

  }

  .navbar-request-narcan2 img {
      color: #ff23fd;
      display: block;
      width: 200px;
      margin-top: 20px; /* Adjust top margin to reduce space */
      margin-left: -10px;
      padding: 0%;
      height: auto;
  
    }

    .sidenav a.navbar-request-narcan2 {
      padding: 0; /* Remove padding for the link */
      margin: 0;  /* Remove margin for the link */
    }
}