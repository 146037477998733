@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap'); /* Nunito Sans */

header {
  width: 100%; /* Ensures the header is the full width of the viewport */
  position: fixed; /* Fixes the navbar at the top */
  top: 0; /* Aligns the navbar at the top of the viewport */
  left: 0; /* Aligns the navbar to the left edge of the viewport */
  z-index: 1000; /* Ensures the navbar stays on top of other content */
  height: 100px; 
  
}

.navbar2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 100%; /* Makes the navbar fill the header */
  background-color: black;
  margin-right: 1em;
  width: 100%;
}

.left-section {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  height: 50px; 
  width: auto;
  vertical-align: middle;
  transition: opacity 0.2s; /* Smooth fade effect on hover */
}

.navbar-logo img:hover {
  opacity: 0.9;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.navbar-links a {
  position:relative;
  margin: 0 15px;
  text-decoration: none;
  color: white;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px; 
  text-align: center;
}

.navbar-links a:hover {
  background: linear-gradient(to right, #6a00f4, #9226f0, #ff5fa8); /* Gradient colors */
  -webkit-background-clip: text;
  color: transparent;
}

.navbar-links a::before {
  content: '';
  color: white;
  margin-right: 15px;
  transform: translateX(-50%);
}

.navbar-request-narcan {
  display: flex;
  align-items: center;
}

.navbar-request-narcan img {
  height: auto; 
  width: 200px;
  vertical-align: middle;
  transition: filter 0.4s;
}

.navbar-request-narcan img:hover {
  filter: brightness(2); /* Light up effect on hover */
}

.navbar-report-overdose {
  display: flex;
  align-items: center;
}

.navbar-report-overdose img {
  height: 115px; 
  width: auto;
  vertical-align: middle;
  margin-right: 50px; 
  transition: filter 0.4s;
}

.navbar-report-overdose img:hover {
  filter: brightness(2); /* Light up effect on hover */
}

.dropdown {
  position: relative;
  margin-right: 20px; 
  margin-left: 10px; 
  margin-bottom: 1px;
  align-items: center;
}

.dropdown-toggle { 
  background-color: transparent;
  border: none;
  color: grey;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  transition: color 0.4s ease;

}

.dropdown-toggle:hover {
  color: white; /* Slightly lighter color on hover */
}

.dropdown-menu { /* Styles for the dropdown menu */
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9); 
  border: 1px solid #444;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  min-width: 150px; 
}



.dropdown-menu a { /* Styles for the dropdown menu items */
  padding: 10px 20px; 
  text-decoration: none;
  color: white;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease;
}


/* Media query to hide navbar-links on mobile and center the logo and button */
@media (max-width: 768px) {
  
  .navbar2 {
    padding: 0;
    margin: 0;  
  }

  .navbar-links {
    display: none;
  }

  .left-section {
    justify-content: center;
    width: 100%;
  }

  .navbar-logo img{
    justify-content: center;
  }
  

  .dropdown {
    position: absolute;
    left: 0px;
  }

  .dropdown-toggle { 
    padding: 0 0 0 0;
  }
  
}
