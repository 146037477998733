@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap'); /* BEBAS NEUE */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@700&display=swap'); /* NUNITO SANS CONDENSED */

body {
    background-color: black;
    color: white;
    margin: 0;
    font-family: 'Bebas Neue', sans-serif;
}

#root {
    height: 100%;
}

main {
    flex: 1;
  }

.AboutUs-page {
    position: relative;
    text-align: center;
    margin: 0;
    padding: 0;
    height: 100vh; 
    overflow: hidden; /* Prevents scrolling */
}
