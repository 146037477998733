.board-container {
    background: linear-gradient(to bottom, #000000, #690078);
    padding-top: 100px; /* Ensure enough space for the heading */
    min-height: 100vh; /* Ensure it covers the entire viewport height */
    color: white; /* Make text white for better readability on dark background */
    position: relative;
    overflow-y: none; /* Enable vertical scrolling */  
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  

}

html, body {
    height: 100%;
    overflow: visible; /* Enable scrolling */
}

.board-heading {
    text-align: center;
    font-size: 65px;
    margin-top: 50px;
    background: linear-gradient(to bottom, #000000, #690078);
    -webkit-background-clip: text;
    background-clip: text;
    border-radius: 50px; /* Make the heading more rounded */
}

.team-section {
    text-align: center;
    margin-top: 10px; /* Adjust the top margin as needed */
    padding-top: 20px; /* Add some padding to push the content down */
    position: relative;
}

.team-heading {
    display: inline-block;
    margin-bottom: 20px;
    background-color: transparent;
    color: white;
    border: none;
    font-size: 30px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, text-shadow 0.3s;
    box-shadow: 
        3px 3px 8px rgba(106, 0, 244, 0.7), 
        5px 5px 15px rgba(146, 38, 240, 0.6), 
        7px 7px 20px rgba(255, 95, 168, 0.5); /* Larger gradient shadow shifted right */
}

.team-heading:hover {
    color: white; /* Keep the text color white */
    box-shadow: 
        3px 3px 8px rgba(106, 0, 244, 0.7), 
        5px 5px 15px rgba(146, 38, 240, 0.6), 
        7px 7px 20px rgb(247, 246, 246); /* Larger gradient shadow shifted right */
}

.box-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    overflow-x: auto; 
}

.board1-container {
    display: flex;
    justify-content: center;
}

.box {
    width: 200px;
    height: 200px;
    margin: 20px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    flex: 0 0 auto;
    
}

.box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.box:hover img {
    transform: scale(1.1);
}

.description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    flex-direction: row; /* Stack content vertically */
    justify-content: center; 
    align-items: center; 
    opacity: 0;
    transition: opacity 0.3s ease;
    padding: 1px;
    box-sizing: border-box;
    text-align: center;
    font-family:'nunito sans';
}
.name {
    font-weight: bold;
    margin-top: 50px;
    font-family: 'nunito sans', sans-serif;
    font-size: 27px;

}

.position {
    font-style: italic;
    font-size: 10px;
    margin-top: 1px;
    font-family: 'nunito sans', sans-serif;

}

.description-text {
    margin-top: 1px; 
}

.box:hover .description {
    opacity: 1;
}

@keyframes sparkle {
    0%, 100% { opacity: 0; transform: translate(0, 0); }
    50% { opacity: 1; transform: translate(5px, -5px); }
}

.sparkle {
    position: absolute;
    width: 5px;
    height: 5px;
    background: white;
    border-radius: 50%;
    animation: sparkle 1.5s infinite;
    pointer-events: none;
}

.sparkle:nth-child(2) { animation-delay: 0.2s; }
.sparkle:nth-child(3) { animation-delay: 0.4s; }
.sparkle:nth-child(4) { animation-delay: 0.6s; }
.sparkle:nth-child(5) { animation-delay: 0.8s; }
.sparkle:nth-child(6) { animation-delay: 1s; }
.sparkle:nth-child(7) { animation-delay: 1.2s; }
.sparkle:nth-child(8) { animation-delay: 1.4s; }
.sparkle:nth-child(9) { animation-delay: 1.6s; }
.sparkle:nth-child(10) { animation-delay: 1.8s; }

.box-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px; /* Space between the boxes */
}

.additional-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -10px; /* Reduce space between the box and the name/position */
}

.name {
    font-weight: bold;
    margin: 5px 0; /* Space between the name and the position */
}

.position {
    font-style: italic;
    font-size: smaller;
}


.button {
    position: relative;
    padding: 5px 10px;
    background-color: transparent;
    top: 350px;
    right: 30px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, text-shadow 0.3s;
    text-decoration: none;
    font-size: 45px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.01); /* Drop shadow */
    z-index: 1; /* Ensure the button content is above the background image */
    overflow: visible; /* Ensure the shadow is visible */
}

.button:hover {
    color: white; /* Keep the text color white */
    box-shadow: 
        3px 3px 8px rgba(106, 0, 244, 0.7), 
        5px 5px 15px rgba(146, 38, 240, 0.6), 
        7px 7px 20px rgba(255, 95, 168, 0.5); /* Larger gradient shadow shifted right */
}


/* Styles for mobile screens */
@media (max-width: 600px) {
    .box-container {
        flex-direction: column; 
        align-items: center;
        padding-top: 20px; /* Add padding to move content down */
    }

    .box {
        width: 80%; 
        max-width: 300px; 
        height: auto; 
        margin: 20px 0;
    }

    .board1-container {
        display: flex;
        justify-content: center;
        padding-top: 20px; /* Add padding to move content down */
    }

    .team-section {
        margin-top: 20px; /* Add margin to move content down */
        margin: 30px;
    }
}

@media (min-width: 400px) and (max-width: 600px) {
    .box-container {
        flex-direction: column; 
    }
}